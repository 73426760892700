import ActivityHandler from './ActivityHandler';

/* babel-plugin-inline-import './activity.sparql' */
const queryTemplate = "SELECT ?activity WHERE {\n  ?activity a _:type;\n      <https://www.w3.org/ns/activitystreams#actor> _:actor;\n      <https://www.w3.org/ns/activitystreams#object> _:object.\n}\n";
import { replaceVariables } from '../util';
/**
 * Handler that finds an activity in the user's data pod
 * Requires:
 * - the `root.user` handler
 * - the `root[...]` resolver
 * - a queryEngine property in the path settings
 */

export default class FindActivityHandler extends ActivityHandler {
  constructor(...args) {
    super(...args);
    this.requireUser = false;
  }

  // Finds all activities in the document matching the given pattern
  async *createResults(activity, document, queryEngine) {
    const query = replaceVariables(queryTemplate, activity);

    for await (const binding of queryEngine.execute(query, document)) yield binding.values().next().value;
  }

}