import ActivityHandler from './ActivityHandler';

/* babel-plugin-inline-import './activity-triples.sparql' */
const queryTemplate = "SELECT ?subject ?predicate ?object WHERE {\n  ?subject a _:type;\n      <https://www.w3.org/ns/activitystreams#actor> _:actor;\n      <https://www.w3.org/ns/activitystreams#object> _:object.\n  ?subject ?predicate ?object.\n}\n";
import { replaceVariables, termToString } from '../util';
const components = ['?subject', '?predicate', '?object'];
/**
 * Handler that deletes an activity in the user's data pod
 * Requires:
 * - the `root.user` handler
 * - the `root[...]` resolver
 * - a queryEngine property in the path settings
 */

export default class DeleteActivityHandler extends ActivityHandler {
  // Finds activity triples for deletion
  async *createResults(activity, document, queryEngine) {
    const query = replaceVariables(queryTemplate, activity);

    for await (const triple of queryEngine.execute(query, document)) {
      const terms = components.map(c => termToString(triple.get(c)));
      yield "".concat(terms.join(' '), ".\n");
    }
  } // Deletes the activity triples from the document


  async processResults(results, document, queryEngine) {
    const sparql = "DELETE {\n".concat(results.join(''), "}");
    await queryEngine.executeUpdate(sparql, document).next();
    return [];
  }

}