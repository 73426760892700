import ActivityHandler from './ActivityHandler';

/* babel-plugin-inline-import './activity.ttl' */
const activityTemplate = "_:id a _:type;\n    <https://www.w3.org/ns/activitystreams#actor> _:actor;\n    <https://www.w3.org/ns/activitystreams#object> _:object;\n    <https://www.w3.org/ns/activitystreams#published> _:published.\n";
import { replaceVariables } from '../util';
import { namedNode, literal } from '@rdfjs/data-model';
import { v4 as uuidv4 } from 'uuid';
import context from '@solid/context';
const {
  xsd
} = context['@context'];
/**
 * Handler that creates an activity in the user's data pod
 * Requires:
 * - the `root.user` handler
 * - the `root[...]` resolver
 * - a queryEngine property in the path settings
 */

export default class CreateActivityHandler extends ActivityHandler {
  // Creates an activity for insertion in the given document
  async *createResults(activity, document) {
    const id = namedNode(new URL("#".concat(uuidv4()), document).href);
    const published = literal(new Date().toISOString(), "".concat(xsd, "dateTime"));
    activity = {
      id,
      published,
      ...activity
    };
    const insert = replaceVariables(activityTemplate, activity);
    yield {
      id,
      insert
    };
  } // Inserts the activities into the document


  async processResults(results, document, queryEngine) {
    const sparql = "INSERT {\n".concat(results.map(r => r.insert).join(''), "}");
    await queryEngine.executeUpdate(sparql, document).next();
    return results.map(r => r.id);
  }

}